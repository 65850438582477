import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { pushToDataLayer } from "utils/gtm";

import { Container, Row, Col } from "reactstrap";

import envelope from "../assets/img/envelope.png";
import phoneIcon from "../assets/img/phone.png";

import Button from "./Button";

function MainSection({
  showSecondRow,
  showImageText,
  showThirdRow,
  showTitle,
  title,
  titleIcon,
  showSubTitle,
  subTitle,
  description,
  showDesc,
  showSub,
  subDescription,
  imageSrc,
  imageText,
  showSeparator,
  additionalContent
}) {
  const [showMore, setShowMore] = useState(false);

  const handleReadMore = () => {
    setShowMore(!showMore);
  }
  const navigate = useNavigate();

  const handleClick = (e, pageUrl) => {
    e.preventDefault();

    pushToDataLayer({
      event: "Services",
      category: "button",
      action: "click",
      label: "Home",
    });

    navigate(pageUrl);
  };

  const emailAndPhoneClick = (event, category, action) => {
    pushToDataLayer({
      event: event,
      category: category,
      action: action,
      label: "Kontakta oss",
    });
  };

  return (
    <div className="main-section">
      <div className="about-description text-center">
        <div className="features-3 main-section-features">
          <Container className="main-section-container">
            <Row className="main-section-row">
              <Col className="mr-auto ml-auto main-section-column">
                {showTitle && <h1 className="main-section-title">{title}</h1>}
                {titleIcon && <img src={titleIcon} className="title-icon" alt="Title Icon" />}
                {showSubTitle && <h1 className="sub-page-title">{subTitle}</h1>}
                <div className="main-section-separator"></div>
                {showImageText && (
                  <Row className="main-section-image-text">
                    <Col md="4" className="main-section-column">
                      <img
                        className="main-section-image"
                        src={imageSrc}
                        alt=""
                      />
                    </Col>
                    <Col md="8" className="main-section-column">
                      {imageText.map((text) => (
                        <p className="sub-page-description">{text}</p>
                      ))}
                    </Col>
                  </Row>
                )}
                {showDesc && (
                  <p className="main-section-description">{description}</p>
                )}
                {/* Additional content that will fade in/out */}
                {additionalContent &&
                  <>
                    <div className={`additional-content ${showMore ? 'fade-in' : 'fade-out'}`}>
                      <p className="main-section-description">
                        Vårt team av professionella operatörer arbetar snabbt och noggrant för att minimera störningar
                        och säkerställa att projektet utförs på ett säkert sätt. Genom att anlita oss på Sugbil får du
                        tillgång till flexibla tjänster som kan skräddarsys efter dina behov, oavsett om det gäller ett
                        privat uppdrag eller större entreprenad.
                      </p>
                      <p className="main-section-description">
                        Vi har hjälpt kunder inom flera olika branscher, däribland bygg, industri, fastighetsförvaltning
                        och offentlig sektor. Tack vare vår expertis och vårt engagemang för kvalitet har vi byggt ett
                        starkt rykte som en pålitlig partner för alla som behöver sugbilstjänster.
                      </p>
                    </div>
                    <Button handleSubmit={handleReadMore} name={showMore ? 'Visa mindre' : 'Läs mer'} />
                  </>}
                {showSub &&
                  subDescription.map((subDesc) => (
                    <p className="sub-page-description">{subDesc}</p>
                  ))}

                {showSeparator && (
                  <div className="main-section-separator"></div>
                )}
              </Col>
            </Row>
            {showSecondRow && (
              <Row className="main-section-row">
                <Col md="6" xs="12" className="main-column-1">
                  <a className="main-column-box" href="/vacuumsug">
                    <div className="info info-hover">
                      <h3 className="info-title">Vacuumsug</h3>
                      <p className="main-column-description">
                        Vacuumsugning med en sugbil är en process som används
                        för att suga upp och transportera olika typer av
                        material och avfall.
                      </p>
                      <Button
                        url="/vacuumsug"
                        name="Läs mer"
                        handleSubmit={(e) => handleClick(e, "/vacuumsug")}
                      />
                    </div>
                  </a>
                </Col>
                <Col md="6" xs="12" className="main-column-2">
                  <a className="main-column-box" href="/materialblasning">
                    <div className="info info-hover">
                      <h3 className="info-title">Materialblåsning</h3>
                      <p className="main-column-description">
                        Med blåsfunktion är det ett effektivt sätt att få upp
                        material på tak, källare eller trånga utrymmen som t.
                        ex. krypgrund.
                      </p>
                      <Button
                        url="/materialblasning"
                        name="Läs mer"
                        handleSubmit={(e) =>
                          handleClick(e, "/materialblasning")
                        }
                      />
                    </div>
                  </a>
                </Col>
                <Col md="6" xs="12" className="main-column-3">
                  <a className="main-column-box" href="/torrsugning">
                    <div className="info info-hover">
                      <h3 className="info-title">Torrsugning</h3>
                      <p className="main-column-description">
                        Där en grävmaskin inte kommer åt kan vi frilägga
                        elkablar, rör mm. Vi kan även grävsuga i trånga
                        utrymmen, t.ex. krypgrund.
                      </p>
                      <Button
                        url="/torrsugning"
                        name="Läs mer"
                        handleSubmit={(e) => handleClick(e, "/torrsugning")}
                      />
                    </div>
                  </a>
                </Col>
                <Col md="6" xs="12" className="main-column-4">
                  <a className="main-column-box" href="/gravsugning">
                    <div className="info info-hover">
                      <h3 className="info-title">Grävsugning</h3>
                      <p className="main-column-description">
                        Torrsugning med en sugbil är en effektiv metod för att
                        ta bort torrt material från ytor och ytor med begränsad
                        tillgänglighet
                      </p>
                      <Button
                        url="/gravsugning"
                        name="Läs mer"
                        handleSubmit={(e) => handleClick(e, "/gravsugning")}
                      />
                    </div>
                  </a>
                </Col>
              </Row>
            )}
            <Row className="main-section-row">
              <Col className="mr-auto ml-auto main-section-column">
                <h3 className="main-section-title mt-5">Enkelt. Smart. Snabbt</h3>
                <p className="main-section-description">Våra sugbilar är utrustade med kraftfulla sugaggregat som hanterar både våta och torra material, som sand, grus, slam och olja. Vi erbjuder tjänster som avloppsspolning, industriell rengöring och slamsugning, skräddarsydda för olika branscher. Med våra specialutrustade lastbilar når vi även trånga och svåråtkomliga platser, som vindar, tak och källare. Vi arbetar alltid nära våra kunder för att leverera effektiva och säkra lösningar, utförda av våra erfarna tekniker.</p>
              </Col>
            </Row>
            {showThirdRow && (
              <Row className="row-contact">
                <Col md="4" className="ml-auto info-contact">
                  <div className="info-box">
                    <div className="icon">
                      <img
                        className="info-contact-icon"
                        src={envelope}
                        alt=""
                      />
                    </div>
                    <h4 className="info-contact-title">
                      <a
                        href="mailto:info@sugbil.nu"
                        onClick={() =>
                          emailAndPhoneClick("email", "email", "click")
                        }>
                        info@sugbil.nu
                      </a>
                    </h4>
                  </div>
                </Col>
                <Col md="4" className="mr-auto info-contact">
                  <div className="info-box info-box-2">
                    <div className="icon">
                      <img
                        className="info-contact-icon"
                        src={phoneIcon}
                        alt=""
                      />
                    </div>
                    <h4 className="info-contact-title">
                      <a
                        href="tel:46766399868"
                        onClick={() =>
                          emailAndPhoneClick("phone_number", "phone", "click")
                        }>
                        076-6399868
                      </a>
                      <br />
                      <br />
                      <a
                        href="tel:+4608398810"
                        onClick={() =>
                          emailAndPhoneClick("phone_number", "phone", "click")
                        }>
                        08-398810
                      </a>
                    </h4>
                  </div>
                </Col>
                <Col className="mr-auto ml-auto main-section-column address-column">
                  <h4 className="main-section-address">
                    Adress: Box 5205151 13<br></br> Södertälje<br></br>
                    <br></br>Besöksadress: Regementsgatan hus 353<br></br> 151
                    66 Södertälje
                  </h4>
                </Col>
              </Row>
            )}
          </Container>
        </div>
      </div>
    </div>
  );
}

export default MainSection;
