import React from "react";

import { Container, Row, Col } from "reactstrap";

function ImageSection({ showTitle }) {
  return (
    <div className="image-section">
      <Container className="image-section-container">
        {showTitle && (
          <Row>
            <Col className="mr-auto ml-auto image-section-column">
              <h4 className="image-section-title">
                Tack för att du väljer att kontakta oss!
              </h4>
            </Col>
          </Row>
        )}
        <Row>
          <Col md="4" xs="12">
            <img
              alt="..."
              className="rounded img-section-image"
              src={require("assets/img/IMG_1.png")}
            />
          </Col>
          <Col md="4" xs="12">
            <img
              alt="..."
              className="rounded img-section-image"
              src={require("assets/img/IMG_3.png")}
            />
          </Col>
          <Col md="4" xs="12">
            <img
              alt="..."
              className="rounded img-section-image"
              src={require("assets/img/IMG_2.png")}
            />
          </Col>
        </Row>
        <Row>
          <Col className="mr-auto ml-auto image-section-column">
            <h3 className="image-section-title mt-5">
              Varför välja vår sugbil för dina behov?
            </h3>
            <h4>Kommunal verksamhet</h4>
            <p className="page-text"> En sugbil kan vara ett värdefullt verktyg för kommuner i flera sammanhang. Den kan användas för att underhålla och rengöra avloppssystem, brunnar och dagvattenledningar, vilket minskar risken för översvämningar och blockeringar. Sugbilar är också effektiva vid hantering av spill och sanering efter olyckor där det krävs att material snabbt avlägsnas från offentliga platser. Vid vägunderhåll kan sugbilar avlägsna grus och jord för att förbereda ytor för reparationer eller lägga ny asfalt. Kommuner kan också nyttja sugbilstjänster för att hålla gator och parker rena från smuts och löv.</p>

            <h4>BRF och fastighetsförvaltning</h4>
            <p className="page-text">En sugbil kan vara ett viktigt verktyg för bostadsrättsföreningar (BRF) och fastighetsförvaltning. Genom att använda sugbilar kan man snabbt och effektivt rensa avloppssystem och brunnar, vilket förebygger översvämningar och blockeringar som kan skada fastigheten. Vid dräneringsarbeten är sugbilar oumbärliga för att avlägsna vatten och jordmassor runt fastighetsgrunder, särskilt på trånga områden där traditionella grävmaskiner är mindre effektiva. Dessutom kan de användas för att underhålla takavvattningssystem och ta bort löv och smuts från fastighetens utemiljö.</p>

            <h4>Industrier och byggföretag</h4>
            <p className="page-text">  En sugbil är ett ovärderligt verktyg för både industrier och byggföretag, särskilt när det gäller hantering av stora mängder material på svåråtkomliga platser. För byggföretag kan sugbilen användas för att avlägsna grus, jord och betongrester vid rivnings- och schaktarbeten. Den är också perfekt för dränering och rensning av byggarbetsplatser där traditionell utrustning är mindre effektiv. Inom industrin kan sugbilar hantera tunga och flytande material, till exempel vid rengöring av siloer, tankar eller vid borttagning av avfall som uppstår vid produktionsprocesser. Detta säkerställer en ren och säker arbetsmiljö samt att produktionsstopp minimeras.</p>

            <h4>Vägunderhåll</h4>
            <p className="page-text">
              Sugbilar kan spela en viktig roll i vägunderhåll genom att snabbt och effektivt avlägsna material som grus, lera och andra avlagringar från vägbanor och vägkanten. De är särskilt användbara vid reparationer och förberedelser för asfaltering, där material behöver avlägsnas för att skapa en jämn yta. Sugbilar kan också användas vid sanering efter olyckor, där de hjälper till att snabbt suga upp spillmaterial eller vatten. Detta bidrar till att hålla vägarna säkra och väl underhållna, samtidigt som de minimerar trafikstörningar.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default ImageSection;
