import AWS from "aws-sdk";

AWS.config.update({
  region: process.env.REACT_APP_AWS_REGION,
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
});

const ses = new AWS.SES({ apiVersion: "2010-12-01" });

export default async function sendEmail(req, res) {
  const { fullName, email, contactNumber, message } = req;

  const params = {
    Destination: {
      ToAddresses: ["nikola.lalovic@np011.se"],
    },
    Message: {
      Body: {
        Text: {
          Data: `Name: ${fullName}\nEmail: ${email}\nNumber: ${contactNumber}\nMessage: ${message}`,
        },
      },
      Subject: { Data: "Kontaktformulär - Sugbil" },
    },
    Source: "nikola.lalovic@np011.se",
  };

  try {
    await ses.sendEmail(params).promise();

    return { success: true };
  } catch (error) {
    console.error("Error sending email:", error);
    return { success: false, error: error.message };
  }
}
