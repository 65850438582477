import React from "react";

import Head from "components/Head";
import MainNavigation from "components/Navbars/MainNavigation";
import MainSection from "components/MainSection";
import LinkSection from "components/LinkSection";
import Background from "components/Background";
import MainFooter from "components/Footers/MainFooter";

import mainImage from "../assets/img/IMG_Vacuumsug.png";
import img_1 from "../assets/img/img1.png";
import img_2 from "../assets/img/img2.png";
import img_3 from "../assets/img/img3.png";
import bg_logo from '../assets/img/bg-logo-final.png';

function Vacuumsug() {
  const menu = [
    { id: 1, label: "Hem", url: "/" },
    { id: 2, label: "Om Oss", url: "/om-sug-och-blas-bil" },
    { id: 3, label: "Kontakta Oss", url: "/kontakta-oss" },
  ];

  const mainTitle = "Vacuumsug";

  const text = [
    "Vacuum sugning med sugbil är en form av industriell rengöringsteknik som används för att ta bort stora mängder av olika material från ytor och områden där det är svårt att nå.",
    "Denna metod används vanligtvis för att rengöra stora industriella anläggningar, byggplatser, avloppsledningar och andra områden där traditionell rengöring inte är tillräckligt effektivt.",
  ];

  const subDesc = [
    "En sugbil är utrustad med en stor behållare och en kraftfull sugpump som kan suga upp allt från vatten, slam och sand till stenar och stora föremål. Denna metod är en effektiv lösning för att ta bort stora mängder av material på kort tid, och den minskar också risken för hälsoproblem som kan uppstå vid manuell rengöring.",
    "En annan fördel med vacuum sugning med sugbil är att den är miljövänlig eftersom det inte krävs några kemikalier eller rengöringsmedel för att utföra arbetet. Dessutom är det en säker och kostnadseffektiv lösning jämfört med traditionella rengöringsmetoder, eftersom det minskar behovet av manuell hantering av avfall och minimerar risken för skador på anläggningen.",
    "Sammanfattningsvis är vacuum sugning med sugbil en effektiv, miljövänlig och säker metod för industriell rengöring som används för att ta bort stora mängder av olika material från svårtillgängliga områden. Det är en viktig teknik som hjälper till att hålla anläggningar och områden rena, säkra och hälsosamma.",
  ];

  return (
    <>
      <Head 
        pageTitle="Vacuumsug - Sugbil - vacuumsugning och materialblåsning"
        pageDesc="Vacuum sugning med sugbil är en form av industriell rengöringsteknik som används för att ta bort stora mängder av olika material från ytor och områden där det är svårt att nå."
        link="vacuumsug/"
      />
      <MainNavigation />
      <Background imgSrc={bg_logo}/>
      <MainSection
        showImageText={true}
        showSubTitle={true}
        subTitle={mainTitle}
        imageSrc={mainImage}
        imageText={text}
        showSub={true}
        subDescription={subDesc}
        showSeparator={false}
      />
      <LinkSection
        showRow={true}
        mainTitle="Våra andra tjänster"
        linkOne="/materialblasning"
        linkTwo="/gravsugning"
        linkThree="/torrsugning"
        imageOne={img_1}
        imageTwo={img_2}
        imageThree={img_3}
        titleOne="Materialblåsning"
        titleTwo="Grävsugning"
        titleThree="Torrsugning"
      />
      <MainFooter menuLinks={menu} />
    </>
  );
}

export default Vacuumsug;
