import React from 'react'
import { Helmet } from 'react-helmet'

function Head({ pageTitle, pageDesc, link }) {
  return (
    <Helmet>
        <title>{pageTitle}</title>
        <link rel="canonical" href={`https://sugbil.nu/${link}`}/>
        <meta name="description" content={pageDesc}/>
        <meta property='og:title' content={pageTitle}/>
        <meta property='og:description' content={pageDesc} />
    </Helmet>
  )
}

export default Head