import React from "react";

import Head from "components/Head";
import MainNavigation from "components/Navbars/MainNavigation";
import MainSection from "components/MainSection";
import LinkSection from "components/LinkSection";
import Background from "components/Background";
import MainFooter from "components/Footers/MainFooter";
import ContactForm from "components/Form";

import img_1 from "../assets/img/mat.png";
import img_2 from "../assets/img/grav.png";
import img_3 from "../assets/img/torr.png";
import img_4 from "../assets/img/vaccum.png";

import bg_logo from "../assets/img/bg-logo-final.png";

function KontaktaOss() {
  const menu = [
    { id: 1, label: "Hem", url: "/" },
    { id: 2, label: "Om Oss", url: "/om-sug-och-blas-bil" },
    { id: 3, label: "Kontakta Oss", url: "/kontakta-oss" },
  ];

  const title = (
    <>
      Enkelt. Smart. Snabbt.
      <br />
      SUG & BLÅS BIL SVERIGE AB
    </>
  );

  const desc =
    "Oavsett vilken kontaktmetod du väljer, så vill vi att du ska känna dig välkommen att höra av dig. Vi vill gärna hjälpa dig på bästa sätt och se till att du får en positiv upplevelse med oss.";

  return (
    <>
      <Head
        pageTitle="Kontakt - Sugbil - vacuumsugning och materialblåsning"
        pageDesc="Kontakta oss info@sugbil.nu Sug & Blåsbil Sverige AB Tel: 0766 39 98 68 Email: zuhdija@sugbil.nu Adress: Box 5205 151 13 Södertälje Besöksadress:Regementsgatan hus 353 , 151 66 Södertälje"
        link="kontakta-oss/"
      />
      <MainNavigation />
      <Background imgSrc={bg_logo} />
      <MainSection
        showSecondRow={false}
        showThirdRow={true}
        showTitle={true}
        title={title}
        showDesc={true}
        description={desc}
        showSeparator={false}
      />
      <ContactForm />
      <LinkSection
        showSecondRow={true}
        mainTitle="Tack för att du väljer att kontakta oss!"
        linkOne="/materialblasning"
        linkTwo="/gravsugning"
        linkThree="/torrsugning"
        linkFour="/vacuumsug"
        imageOne={img_1}
        imageTwo={img_2}
        imageThree={img_3}
        imageFour={img_4}
        titleOne="Materialblåsning"
        titleTwo="Grävsugning"
        titleThree="Torrsugning"
        titleFour="Vacuumsug"
      />
      <MainFooter menuLinks={menu} />
    </>
  );
}

export default KontaktaOss;
