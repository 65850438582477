import React, { useState } from "react";
import { Form, FormGroup, Input, Alert } from "reactstrap";
import { useNavigate } from "react-router-dom";
import Button from "./Button";

import sendEmail from "utils/contact";
import validateEmail from "../validation/emailValidation";
import validatePhoneNumber from "validation/phoneValidation";
import { pushToDataLayer } from "utils/gtm";

function ContactForm() {
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    contactNumber: "",
    message: "",
  });

  const navigate = useNavigate();

  const [errors, setErrors] = useState({});
  const [submitError, setSubmitError] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { fullName, email, contactNumber, message } = formData;
    let formErrors = {};

    if (!fullName) formErrors.fullName = "Fullständiga namn är obligatoriskt";
    if (!email) formErrors.email = "E-post är obligatoriskt";
    else if (!validateEmail(email)) formErrors.email = "Ogiltig e-postadress";
    if (!contactNumber)
      formErrors.contactNumber = "Telefonnummer är obligatoriskt";
    else if (!validatePhoneNumber(contactNumber))
      formErrors.contactNumber = "Ogiltigt svenskt telefonnummer";
    if (!message) formErrors.message = "Meddelande är obligatoriskt";

    if (Object.keys(formErrors).length === 0) {
      try {
        const response = await sendEmail(formData);
        if (response.success) {
          pushToDataLayer({
            event: "form_submit",
            category: "email",
            action: "submit",
            label: "Kontakta oss",
          });

          setFormData({
            fullName: "",
            email: "",
            contactNumber: "",
            message: "",
          });

          navigate("/tack");
        } else {
          setSubmitError(
            "Ett fel uppstod vid hantering av din förfrågan. Försök igen senare."
          );
        }
      } catch (error) {
        console.error("Error:", error);
        setSubmitError(
          "Ett fel uppstod vid hantering av din förfrågan. Försök igen senare."
        );
      }
    } else {
      setErrors(formErrors);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: null });
    setSubmitError(null);
  };

  return (
    <>
      {/* <Form className="contact-form" action="https://formsubmit.co/aec2cee3f811a9283dc066de95967563" method="POST"> */}
      <Form className="contact-form">
        <h2>Kontakta oss</h2>
        <FormGroup>
          <label>Fullständiga namn</label>
          <Input
            name="fullName"
            value={formData.fullName}
            onChange={handleChange}
            required
            placeholder="Fullständiga namn"
            type="text"
            className="contact-form-input"
            invalid={!!errors.fullName}
          />
          {errors.fullName && (
            <div className="text-danger">{errors.fullName}</div>
          )}
        </FormGroup>
        <FormGroup>
          <label>E-post</label>
          <Input
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
            placeholder="E-post"
            type="email"
            className="contact-form-input"
            invalid={!!errors.email}
          />
          {errors.email && <div className="text-danger">{errors.email}</div>}
        </FormGroup>
        <FormGroup>
          <label>Telefonnummer</label>
          <Input
            name="contactNumber"
            value={formData.contactNumber}
            onChange={handleChange}
            required
            type="tel"
            placeholder="+46 XX-XXX XX XX"
            // pattern="\+46\s[0-9]{2}-[0-9]{3}\s[0-9]{2}\s[0-9]{2}|0046\s[0-9]{2}-[0-9]{3}\s[0-9]{2}\s[0-9]{2}"
            patter="^(?:\+46|0046|0)(\s?\d{2}[-\s]?\d{3}[-\s]?\d{2}[-\s]?\d{2})$"
            className="contact-form-input"
            invalid={!!errors.contactNumber}
          />
          {errors.contactNumber && (
            <div className="text-danger">{errors.contactNumber}</div>
          )}
        </FormGroup>
        <FormGroup>
          <label>Meddelande</label>
          <Input
            name="message"
            value={formData.message}
            onChange={handleChange}
            required
            rows="3"
            type="textarea"
            className="contact-form-input"
            invalid={!!errors.message}
          />
          {errors.message && (
            <div className="text-danger">{errors.message}</div>
          )}
        </FormGroup>
        {/* <FormGroup>
          <Input
            type="hidden"
            name="_next"
            value="https://sugbil.nu/tack"
          />
        </FormGroup>
        <Button name="Skicka" type="submit" /> */}
        <Button name="Skicka" type="submit" handleSubmit={handleSubmit} />
        {submitError && (
          <Alert color="danger" className="contact-form-error">
            {submitError}
          </Alert>
        )}
      </Form>
    </>
  );
}

export default ContactForm;
