import React from "react";

import { Container } from "reactstrap";
import Button from "./Button";
import { useNavigate } from "react-router-dom";
import { pushToDataLayer } from "utils/gtm";

function Hero({ imageHeight, imageUrl, heroText }) {
  const navigate = useNavigate();

  const handleClick = (e, pageUrl) => {
    e.preventDefault();

    pushToDataLayer({
      event: "Contact",
      category: "button",
      action: "click",
      label: "Kontakta oss",
    });

    navigate(pageUrl);
  };
  return (
    <Container
      className="hero-img"
      style={{
        backgroundImage: `url(${imageUrl})`,
        height: imageHeight,
      }}
    >
      {heroText && <div className="hero-content">
        <h1>Sugbil</h1>
        <h2>Sug & Blåsbil Sverige AB</h2>
        <p>
          Erbjuder Sug & Blåsbilar i Stockholm <br></br>
          med vaccumsug, meterial, torrsugning, grävsugning mm.
        </p>
        <div className="hero-button-wrapper">
          <Button
            url="/kontakta-oss"
            name="Kontakta oss"
            handleSubmit={(e) => handleClick(e, "/kontakta-oss")}
          />
        </div>
      </div>}
    </Container>
  );
}

export default Hero;
