import React from 'react'

function Background({ imgSrc }) {
  return (
    <div className='bg-wrapper'>
        <img src={imgSrc} alt="" />
    </div>
  )
}

export default Background