import React from "react";

import { Container, Button } from "reactstrap";

function MainFooter({ menuLinks }) {
  return (
    <footer className="footer" data-background-color="black">
      <Container className="footer-container">
        <ul className="pull-left footer-menu">
          {menuLinks.map((link) => (
            <li key={link.id}>
              <a href={link.url}>{link.label}</a>
            </li>
          ))}
        </ul>
        <ul className="social-buttons pull-center">
          <li className="social-icon-li">
            <Button
              className="btn-icon btn-neutral"
              color="link"
              href="https://www.facebook.com/people/Sugbilnu/100092007168800/"
              target="_blank"
            >
              <i className="fab fa-facebook-square"></i>
            </Button>
          </li>
          <li className="social-icon-li">
            <Button
              className="btn-icon btn-neutral"
              color="link"
              href="https://www.instagram.com/sugbil.nu/"
              target="_blank"
            >
              <i className="fab fa-instagram"></i>
            </Button>
          </li>
        </ul>
        <p>© 2023, SUG & BLÅSBIL SVERIGE AB</p>
      </Container>
    </footer>
  );
}

export default MainFooter;
