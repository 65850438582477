import React from "react";

import Head from "components/Head";
import MainNavigation from "components/Navbars/MainNavigation";
import MainSection from "components/MainSection";
import LinkSection from "components/LinkSection";
import Background from "components/Background";
import MainFooter from "components/Footers/MainFooter";

import mainImage from "../assets/img/IMG_Gravsugning.png";
import img_1 from "../assets/img/img4.png";
import img_2 from "../assets/img/img1.png";
import img_3 from "../assets/img/img3.png";
import bg_logo from '../assets/img/bg-logo-final.png';

function Gravsugning() {
  const menu = [
    { id: 1, label: "Hem", url: "/" },
    { id: 2, label: "Om Oss", url: "/om-sug-och-blas-bil" },
    { id: 3, label: "Kontakta Oss", url: "/kontakta-oss" },
  ];

  const mainTitle = "Grävsugning";

  const text = [
    "Grävsugning med blåsbil är en effektiv metod för att ta bort jord och andra material från en plats. Det är en process som används på byggplatser, gruvor och andra industriella platser där det krävs att jord och andra material tas bort på ett säkert och effektivt sätt.",
    "En blåsbil används för att skapa ett vakuum som suger upp jord och andra material från en plats.",
    "Den höga hastigheten av luftströmmen i blåsbilen skapar ett tryck som suger upp jorden och andra material och transporterar det till en behållare på blåsbilen.",
  ];

  const subDesc = [
    "Denna metod är ett säkert och effektivt sätt att ta bort jord och andra material från en plats, eftersom den minimerar risken för skador på personalen och minskar risken för skador på omgivande strukturer. Det är också en snabb metod som sparar tid och pengar, eftersom den minskar behovet av manuell hantering av material.",
    "Grävsugning med blåsbil kan användas för att ta bort jord, stenar, grus och andra material från olika platser, inklusive trånga utrymmen där det kan vara svårt att ta bort material på andra sätt. Det är också en miljövänlig metod eftersom det minskar mängden material som transporteras till avfallsdeponier och minskar koldioxidutsläppen från transporter.",
    "Det är viktigt att notera att det krävs erfaren och kvalificerad personal för att utföra grävsugning med blåsbil, eftersom höga hastigheter av luftströmmen kan vara farliga om de inte hanteras på rätt sätt. Dessutom kräver denna metod att rätt utrustning används för att säkerställa effektiviteten och säkerheten.",
    "Sammanfattningsvis är grävsugning med blåsbil en effektiv och säker metod för att ta bort jord och andra material från en plats. Det är en snabb och miljövänlig metod som sparar tid och pengar och minskar behovet av manuell hantering av material. Men det kräver erfaren och kvalificerad personal samt rätt utrustning för att säkerställa effektiviteten och säkerheten.",
  ];

  return (
    <>
      <Head 
        pageTitle="Grävsugning - Sugbil - vacuumsugning och materialblåsning"
        pageDesc="Grävsugning med blåsbil är en effektiv metod för att ta bort jord och andra material från en plats. Det är en process som används på byggplatser, gruvor och andra industriella platser där det krävs att jord och andra material tas bort på ett säkert och effektivt sätt."
        link="gravsugning/"
      />
      <MainNavigation />
      <Background imgSrc={bg_logo}/>
      <MainSection
        showImageText={true}
        showSubTitle={true}
        subTitle={mainTitle}
        imageSrc={mainImage}
        imageText={text}
        showSub={true}
        subDescription={subDesc}
        showSeparator={false}
      />
      <LinkSection
        showRow={true}
        mainTitle="Våra andra tjänster"
        linkOne="/torrsugning"
        linkTwo="/vacuumsug"
        linkThree="/materialblasning"
        imageOne={img_3}
        imageTwo={img_1}
        imageThree={img_2}
        titleOne="Torrsugning"
        titleTwo="Vacuumsug"
        titleThree="Materialblåsning"
      />
      <MainFooter menuLinks={menu} />
    </>
  );
}

export default Gravsugning;
