import React from "react";

import MainNavigation from "components/Navbars/MainNavigation";
import Background from "components/Background";
import MainSection from "components/MainSection";
import MainFooter from "components/Footers/MainFooter";

import bg_logo from "../assets/img/bg-logo-final.png";
import icon from "../assets/img/smile.png";

function ThankYou() {
  const menu = [
    { id: 1, label: "Hem", url: "/" },
    { id: 2, label: "Om Oss", url: "/om-sug-och-blas-bil" },
    { id: 3, label: "Kontakta Oss", url: "/kontakta-oss" },
  ];

  const title = <>Tack!</>;

  const desc =
    "Vi uppskattar din feedback och engagemang. Ditt bidrag är ovärderligt för oss och hjälper oss att förbättra våra tjänster. Vår kundtjänst kommer att granska din återkoppling och återkomma till dig så snart som möjligt. Tack för att du hjälper oss att bli bättre och för att du är en del av vår gemenskap.";

  return (
    <>
      <div className="layout">
        <MainNavigation />
        <Background imgSrc={bg_logo} />
        <main className="content">
          <MainSection
            showSecondRow={false}
            showThirdRow={false}
            showTitle={true}
            title={title}
            titleIcon={icon}
            showDesc={true}
            description={desc}
            showSeparator={false}
          />
        </main>
        <MainFooter menuLinks={menu} />
      </div>
    </>
  );
}

export default ThankYou;
