import React from "react";
import { Container, Row, Col } from "reactstrap";

function LinkSection({
  showRow,
  showSecondRow,
  showMainTitle,
  mainTitle,
  linkOne,
  linkTwo,
  linkThree,
  linkFour,
  imageOne,
  imageTwo,
  imageThree,
  imageFour,
  titleOne,
  titleTwo,
  titleThree,
  titleFour
}) {
  return (
    <div className="link-section">
      <Container className="link-section-container">
        {(showMainTitle &&
        <Row>
          <Col className="mr-auto ml-auto link-section-main-title">
            <h4 className="link-section-title">{mainTitle}</h4>
          </Col>
        </Row>
        )}
        {( showRow &&
        <Row className="mt-4">
          <Col md="4" className="link-section-column">
            <a href={linkOne} className="image-link">
              <img
                src={imageOne}
                alt=""
                className="img-fluid d-block mx-auto mb-3"
              />
              <h5 className="image-title">{titleOne}</h5>
            </a>
          </Col>
          <Col md="4" className="link-section-column">
            <a href={linkTwo} className="image-link">
              <img
                src={imageTwo}
                alt=""
                className="img-fluid d-block mx-auto mb-3"
              />
              <h5 className="image-title">{titleTwo}</h5>
            </a>
          </Col>
          <Col md="4" className="link-section-column">
            <a href={linkThree} className="image-link">
              <img
                src={imageThree}
                alt=""
                className="img-fluid d-block mx-auto mb-3"
              />
              <h5 className="image-title">{titleThree}</h5>
            </a>
          </Col>
        </Row>
        )}
        {( showSecondRow &&
        <Row className="rowSecond">
          <Col md="3" className="link-section-column link-four-section-column">
            <a href={linkOne} className="image-link">
              <img
                src={imageOne}
                alt=""
                className="img-fluid d-block mx-auto mb-3"
              />
              <h5 className="image-title">{titleOne}</h5>
            </a>
          </Col>
          <Col md="3" className="link-section-column link-four-section-column">
            <a href={linkTwo} className="image-link">
              <img
                src={imageTwo}
                alt=""
                className="img-fluid d-block mx-auto mb-3"
              />
              <h5 className="image-title">{titleTwo}</h5>
            </a>
          </Col>
          <Col md="3" className="link-section-column link-four-section-column">
            <a href={linkThree} className="image-link">
              <img
                src={imageThree}
                alt=""
                className="img-fluid d-block mx-auto mb-3"
              />
              <h5 className="image-title">{titleThree}</h5>
            </a>
          </Col>
          <Col md="3" className="link-section-column link-four-section-column">
            <a href={linkFour} className="image-link">
              <img
                src={imageFour}
                alt=""
                className="img-fluid d-block mx-auto mb-3"
              />
              <h5 className="image-title">{titleFour}</h5>
            </a>
          </Col>
        </Row>
        )}
      </Container>
    </div>
  );
}

export default LinkSection;
