import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

// styles
import "assets/css/bootstrap.min.css";
import "assets/scss/now-ui-kit.scss?v=1.5.0";

// pages
import Home from "views/Home";
import OmOss from "views/OmOss";
import KontaktaOss from "views/KontaktaOss";
import Vacuumsug from "views/Vacuumsug";
import Materialblasning from "views/Materialblasning";
import Gravsugning from "views/Gravsugning";
import Torrsugning from "views/Torrsugning";
import ThankYou from "views/ThankYou";

// others
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" index element={<Home />} />
      <Route path="/om-sug-och-blas-bil" element={<OmOss />} />
      <Route path="/vad-vi-arbetar-med" element={<OmOss />} />
      <Route path="/kontakta-oss" element={<KontaktaOss />} />
      <Route path="/lediga-tjanster" element={<KontaktaOss />} />
      <Route path="/vacuumsug" element={<Vacuumsug />} />
      <Route path="/materialblasning" element={<Materialblasning />} />
      <Route path="/gravsugning" element={<Gravsugning />} />
      <Route path="/torrsugning" element={<Torrsugning />} />
      <Route path="/tack" element={<ThankYou />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  </BrowserRouter>
);
