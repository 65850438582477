import React from "react";
import { Container } from "reactstrap";

function Button({ name, url, borderRadius, handleSubmit }) {
  const borderStyle = {
    borderRadius: `${borderRadius}px`,
  };

  return (
    <Container className="sugbil-btn-container">
      {handleSubmit ? (
        <button
          className="sugbil-btn"
          style={borderStyle}
          onClick={handleSubmit}>
          {name}
        </button>
      ) : (
        <a href={url} rel="noopener noreferrer">
          <button className="sugbil-btn" style={borderStyle}>
            {name}
          </button>
        </a>
      )}
    </Container>
  );
}

export default Button;
