import React, { useState, useEffect } from "react";
import CookieConsent from "react-cookie-consent";
import {
  NavLink,
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  Container,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import logo from "../../assets/img/logo-sugbil.png";
import phoneIcon from "../../assets/img/phone-button.png";
import emailIcon from "../../assets/img/envelope.png";

function MainNavigation() {
  const [collapseOpen, setCollapseOpen] = useState(false);
  const [showBanner, setShowBanner] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowBanner(true);
    }, 3000);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar color="#fff" expand="lg">
        <Container className="nav-container">
          <div className="navbar-translate">
            <NavbarBrand href="/home">
              <img className="nav-logo" src={logo} alt="Logo" />
            </NavbarBrand>
            <button
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setCollapseOpen(!collapseOpen);
              }}
              aria-expanded={collapseOpen}
              className="navbar-toggler"
            >
              <span className="navbar-toggler-bar top-bar"></span>
              <span className="navbar-toggler-bar middle-bar"></span>
              <span className="navbar-toggler-bar bottom-bar"></span>
            </button>
          </div>
          <Collapse isOpen={collapseOpen} navbar>
            <Nav navbar>
              <NavItem>
                <NavLink href="/">
                  <span className="nav-items">Hem</span>
                </NavLink>
              </NavItem>

              {/* Dropdown for Services */}
              <NavItem>
                <Dropdown
                  isOpen={dropdownOpen}
                  toggle={toggleDropdown}
                  nav
                  inNavbar
                >
                  <DropdownToggle nav caret>
                    <span className="nav-items">Tjänster</span>
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem href="/vacuumsug">Vacuumsug</DropdownItem>
                    <DropdownItem href="/materialblasning">
                      Materialblåsning
                    </DropdownItem>
                    <DropdownItem href="/torrsugning">Torrsugning</DropdownItem>
                    <DropdownItem href="/gravsugning">Grävsugning</DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </NavItem>

              <NavItem>
                <NavLink href="/om-sug-och-blas-bil">
                  <span className="nav-items">OM OSS</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/kontakta-oss">
                  <span className="nav-items">KONTAKTA OSS</span>
                </NavLink>
              </NavItem>
            </Nav>
          </Collapse>
          <div className="contact-icons">
            <a className="phone-icon" href="tel:+468398810">
              <img src={phoneIcon} alt="Phone Icon" />
              <span className="phone-number">08-398810</span>
            </a>
            <a className="email-icon" href="mailto:info@sugbil.nu">
              <img src={emailIcon} alt="Email Icon" />
              <span className="email-text">info@sugbil.nu</span>
            </a>
          </div>
        </Container>
      </Navbar>

      {showBanner && (
        <CookieConsent
          location="bottom"
          cookieName="Sugbil Cookie"
          hideOnAccept={true}
          cookieValue={true}
          expires={30}
          style={{
            background: "#2C2C2C",
            padding: "10px 20px",
            fontSize: "16px",
          }}
          buttonText="Jag håller med"
          buttonStyle={{
            color: "#fff",
            background: "#53C226",
            fontFamily: "Montserrat",
            fontSize: "14px",
            borderRadius: "19px",
            padding: "10px 15px",
            outline: "none",
          }}
        >
          Denna webbplats använder cookies för att förbättra
          användarupplevelsen.
        </CookieConsent>
      )}
    </>
  );
}

export default MainNavigation;
