import React from "react";

import Head from "components/Head";
import MainNavigation from "components/Navbars/MainNavigation";
import MainSection from "components/MainSection";
import LinkSection from "components/LinkSection";
import Background from "components/Background";
import MainFooter from "components/Footers/MainFooter";

import mainImage from "../assets/img/IMG_Torrsugning.png";
import img_1 from "../assets/img/img4.png";
import img_2 from "../assets/img/img1.png";
import img_3 from "../assets/img/img2.png";
import bg_logo from '../assets/img/bg-logo-final.png';

function Torrsugning() {
  const menu = [
    { id: 1, label: "Hem", url: "/" },
    { id: 2, label: "Om Oss", url: "/om-sug-och-blas-bil" },
    { id: 3, label: "Kontakta Oss", url: "/kontakta-oss" },
  ];

  const mainTitle = "Torrsugning";

  const text = [
    "Torrsugning med blåsbil är en metod för att ta bort damm, grus och andra torra material från en yta eller plats. Det är en effektiv och snabb metod som används på byggplatser, industriella platser och andra områden där torra material behöver avlägsnas på ett säkert och effektivt sätt.",
    "En blåsbil används för att skapa ett kraftigt vakuum som suger upp damm och andra torra material från ytan eller platsen. Torrsugning med blåsbil är vanligtvis utförd med hjälp av en specialdesignad slang som är ansluten till blåsbilen. Denna slang kan placeras på ytan eller platsen där torra material behöver avlägsnas.",
  ];

  const subDesc = [
    "Torrsugning med blåsbil är en mycket effektiv metod eftersom det tar bort alla torra material som finns på ytan eller platsen. Detta inkluderar små partiklar som annars kan vara svåra att se och ta bort. Dessutom minskar denna metod risken för att damm och andra torra material sprids i luften och orsakar hälsoproblem för människor som arbetar på platsen.",
    "Torrsugning med blåsbil är också ett miljövänligt alternativ eftersom det minskar mängden torra material som behöver transporteras till avfallsdeponier. Det är också ett snabbt och kostnadseffektivt sätt att avlägsna torra material, vilket minskar tiden som krävs för manuell hantering av materialet.",
    "Innebär kraftiga luftströmmar och högt vakuumtryck kan det vara farligt om den inte hanteras korrekt. Dessutom är det viktigt att ha rätt typ av utrustning och slangar för att säkerställa effektiviteten och säkerheten.",
    "Sammanfattningsvis är torrsugning med blåsbil en effektiv och miljövänlig metod för att avlägsna damm, grus och andra torra material från en yta eller plats. Det är också en snabb och kostnadseffektiv metod, men det kräver erfaren personal och rätt utrustning för att utföras på ett säkert och effektivt sätt.",
  ];

  return (
    <>
      <Head 
        pageTitle="Torrsugning - Sugbil - vacuumsugning och materialblåsning"
        pageDesc="Torrsugning med blåsbil är en metod för att ta bort damm, grus och andra torra material från en yta eller plats. Det är en effektiv och snabb metod som används på byggplatser, industriella platser och andra områden där torra material behöver avlägsnas på ett säkert och effektivt sätt."
        link="torrsugning/"
      />
      <MainNavigation />
      <Background imgSrc={bg_logo}/>
      <MainSection
        showImageText={true}
        showSubTitle={true}
        subTitle={mainTitle}
        imageSrc={mainImage}
        imageText={text}
        showSub={true}
        subDescription={subDesc}
        showSeparator={false}
      />
      <LinkSection
        showRow={true}
        mainTitle="Våra andra tjänster"
        linkOne="/vacuumsug"
        linkTwo="/materialblasning"
        linkThree="/gravsugning"
        imageOne={img_1}
        imageTwo={img_2}
        imageThree={img_3}
        titleOne="Vacuumsug"
        titleTwo="Materialblåsning"
        titleThree="Grävsugning"
      />
      <MainFooter menuLinks={menu} />
    </>
  );
}

export default Torrsugning;
