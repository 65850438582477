import React from "react";

import Head from "components/Head";
import MainNavigation from "components/Navbars/MainNavigation";
import Hero from "components/Hero";
import MainSection from "components/MainSection";
import ImageSection from "components/ImageSection";
import Background from "components/Background";
import MainFooter from "components/Footers/MainFooter";

import homeHero from "../assets/img/hero-img.png";
import bg_logo from "../assets/img/bg-logo-final.png";

function Home() {
  const menu = [
    { id: 1, label: "Hem", url: "/" },
    { id: 2, label: "Om Oss", url: "/om-sug-och-blas-bil" },
    { id: 3, label: "Kontakta Oss", url: "/kontakta-oss" },
  ];

  const title = "Vacuumsug. Materialblåsning. Torrsugning. Grävsugning.";
  const desc =
    "Vår moderna fordonsflotta, utrustad med kraftfulla sugenheter, kan suga upp både våta och torra material på svåråtkomliga platser. Med sugbilens höga precision kan vi enkelt hantera allt från rensning av brunnar till dränering och underhåll av fastigheter. Sugbilen är särskilt effektiv för borttagning av tunga material där traditionella metoder är opraktiska eller tidskrävande.";

  return (
    <>
      <Head
        pageTitle="Professionell sugbilstjänst för slam och avfall | Sugbil"
        pageDesc="Metabeskrivning: Sugbil erbjuder snabb, effektiv och miljömedveten avfallshantering. Boka våra tjänster idag och få högkvalitativ service! | Sug & Blåsbil Sverige AB"
        link=""
      />
      <MainNavigation />
      <Hero
        imageUrl={homeHero}
        showTitle={true}
        imageHeight="85vh"
        heroText={true}
      />
      <Background imgSrc={bg_logo} />
      <MainSection
        showTitle={true}
        title={title}
        showDesc={true}
        description={desc}
        additionalContent={true}
        showSecondRow={true}
        showThirdRow={false}
        showSeparator={false}
      />
      <ImageSection showTitle={false} />
      <MainFooter menuLinks={menu} />
    </>
  );
}

export default Home;
