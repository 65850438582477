import React from "react";

import Head from "components/Head";
import MainNavigation from "components/Navbars/MainNavigation";
import MainSection from "components/MainSection";
import LinkSection from "components/LinkSection";
import Background from "components/Background";
import MainFooter from "components/Footers/MainFooter";

import mainImage from "../assets/img/IMG_Materialblåsning.png";
import img_1 from "../assets/img/img4.png";
import img_2 from "../assets/img/img2.png";
import img_3 from "../assets/img/img3.png";
import bg_logo from '../assets/img/bg-logo-final.png';

function Materialblasning() {
  const menu = [
    { id: 1, label: "Hem", url: "/" },
    { id: 2, label: "Om Oss", url: "/om-sug-och-blas-bil" },
    { id: 3, label: "Kontakta Oss", url: "/kontakta-oss" },
  ];

  const mainTitle = "Materialblåsning";

  const text = [
    "Materialblåsning med blåsbil är en effektiv metod för att transportera och hantera stora mängder material på byggplatser, gruvor och andra industriella platser. Det är en process där en stor mängd luft används för att flytta material från en plats till en annan.",
    "Blåsbilen är utrustad med en stor behållare och en kraftfull motor som genererar en hög hastighet luftström. Den höga hastigheten av luftströmmen kan flytta material som sand, grus, stenar, träspån och andra typer av bulkmaterial från en plats till en annan med hög hastighet och precision.",
    "Denna metod är ett säkert och effektivt sätt att transportera material på byggplatser och andra industriella platser, eftersom det minskar behovet av manuell hantering av material och minimerar risken för olyckor och skador på personalen.",
  ];

  const subDesc = [
    "Denna metod är ett säkert och effektivt sätt att transportera material på byggplatser och andra industriella platser, eftersom det minskar behovet av manuell hantering av material och minimerar risken för olyckor och skador på personalen.",
    "Materialblåsning med blåsbil har också fördelen att det kan användas för att rengöra ytor och områden som är svåra att nå. Den höga hastigheten av luftströmmen kan ta bort skräp och smuts från ytor och hålla området rent och säkert.",
    "Det är viktigt att notera att det krävs en erfaren och kvalificerad personal för att utföra materialblåsning med blåsbil, eftersom höga hastigheter av luftströmmen kan vara farliga om de inte hanteras på rätt sätt. Dessutom kräver denna metod att rätt utrustning används för att säkerställa effektiviteten och säkerheten.",
    "Sammanfattningsvis är materialblåsning med blåsbil en effektiv metod för att transportera och hantera stora mängder material på byggplatser, gruvor och andra industriella platser. Det är också en viktig metod för att rengöra ytor och områden som är svåra att nå. Men det kräver erfaren och kvalificerad personal samt rätt utrustning för att säkerställa effektiviteten och säkerheten.",
  ];

  return (
    <>
      <Head 
        pageTitle="Materialblåsning - Sugbil - vacuumsugning och materialblåsning"
        pageDesc="Materialblåsning med blåsbil är en effektiv metod för att transportera och hantera stora mängder material på byggplatser, gruvor och andra industriella platser. Det är en process där en stor mängd luft används för att flytta material från en plats till en annan."
        link="materialblasning/"
      />
      <MainNavigation />
      <Background imgSrc={bg_logo}/>
      <MainSection
        showImageText={true}
        showSubTitle={true}
        subTitle={mainTitle}
        imageSrc={mainImage}
        imageText={text}
        showSub={true}
        subDescription={subDesc}
        showSeparator={false}
      />
      <LinkSection
        showRow={true}
        mainTitle="Våra andra tjänster"
        linkOne="/vacuumsug"
        linkTwo="/gravsugning"
        linkThree="/torrsugning"
        imageOne={img_1}
        imageTwo={img_2}
        imageThree={img_3}
        titleOne="Vacuumsug"
        titleTwo="Grävsugning"
        titleThree="Torrsugning"
      />
      <MainFooter menuLinks={menu} />
    </>
  );
}

export default Materialblasning;
